#article {
    padding: 30px 0;

    img.news {
        float: left;
        margin-right: 15px;
        margin-bottom: 15px;
    }

    span.source {
        display: block;
        font-size: 12px;
        color: #888;
    }

    .addthis_inline_share_toolbox {
        margin: 15px 0;
    }
}