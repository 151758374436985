.contact-wrapper {
    background-color: #fff;
    padding-top: 20px;

    p {
        margin-bottom: 30px;
    }

    .form-wrapper {
        input[type='text'] {
            display: block;
            border-radius: 0;
            height: unset;
            padding: 13px 15px;
        }
    }

    ul {
        text-align: center;

        li {
            display: inline-block;
            margin-right: 50px;

            h2 {
                font-size: 16px;
            }

            a {
                &.email {
                    color: #C42D46;
                    font-size: 14px;
                }

                &.phone {
                    color: #000;
                    font-size: 20px;
                }
            }
        }
    }

    .usp-wrapper{
        margin-top: 50px;
        border: 1px solid #f2f2f2;
        border-radius: 3px;
        box-shadow: 20px 25px 30px 0px rgba(0, 0, 0, 0.1);
        padding: 15px;
        h3{
            color: #333333;
        }
        ul, li{
            text-align: left;
            list-style-type: circle;
        }
    }
}

@media (min-width: $screen-md-min) {
    .contact-wrapper {
        .form-wrapper {
            input[type='text'] {
                padding: 15px 15px;
                
                &::placeholder {
                    font-size: 15px;
                }
            }
        }

        ul {
            li {
                h2 {
                    font-size: 18px;
                }

                a {
                    &.email {
                        font-size: 16px;
                    }

                    &.phone {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .contact-wrapper {
        .form-wrapper {
            input[type='text'] {
                padding: 10px 10px;
                border-color: #0aa2de;
                border-radius: 3px;

                &::placeholder {
                    font-size: 16px;
                }
            }

            textarea[type='textarea'] {
                border-color: #0aa2de;
            }
        }

        ul {
            li {
                h2 {
                    font-size: 20px;
                }

                a {
                    &.email {
                        font-size: 18px;
                    }

                    &.phone {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}