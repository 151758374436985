#news {
    .articles {
        .article {
            text-decoration: none;
            color: initial;

            .top {
                img {
                    width: 100%;
                }
            }
            .bottom {
                padding: 15px 0;
                
                h3 {
                    margin-top: 0;
                    margin-bottom: 15px;
                }
                span.date {
                    display: block;
                    font-size: 14px;
                    color: #888;
                    margin-bottom: 10px;
                }
            }
        }
    }
}