.btn-buddies {
    padding: 12px 15px;
    background-color: #0aa2de;
    color: #fff;
    border-radius: 3px;
    font-weight: 600;

    font-size: 16px;
    -webkit-appearance: caret !important;
    -moz-appearance: caret !important;
    appearance: caret !important;
}

.btn-gold {
    padding: 12px 25px;
    background-color: #0aa2de;
    color: #fff;
    border-radius: 3px;
    font-weight: 700;
    font-size: 16px;
    margin-left: 20px;
   
    -webkit-appearance: caret !important;
    -moz-appearance: caret !important;
    appearance: caret !important;
}

.btn-white {
    padding: 12px 25px;
    background-color: #fff;
    color: #1c1a2c;
    border-radius: 3px;
    font-weight: 700;
    font-size: 18px;
   
    -webkit-appearance: caret !important;
    -moz-appearance: caret !important;
    appearance: caret !important;
}

@media (min-width: $screen-sm-min) {
    .btn-buddies {
        font-size: 16px;
    }
}

@media (min-width: $screen-md-min) {
    .btn-buddies {
        padding: 15px 20px;
        font-size: 18px;
    }
}

@media (min-width: $screen-lg-min) {
    .btn-buddies {
        padding: 15px 25px;
    }
} 