footer {
  background: #1c1a2c;
  background-size: cover;
  background-position: center;

  .content-footer-wrapper-left {
    text-align: center;

    img {
      &.reocLogo {
        max-height: 50px;
      }

    }

  }

  .content-footer-wrapper-middle {
    margin-top: 30px;
    text-align: center;

    .icons {
      margin: 0;
      padding: 0;
      //font-size: 18px;

      li {
        display: block;
        list-style: none;
        margin-right: 25px;
        color: #fff;
        .icon {
          margin-right: 5px;
          color: #fff;
        }
      }
    }
  }

  .content-footer-wrapper-right {
    color: #ff0000;
    text-align: center;
    margin-top: 30px;

    ul {
      margin-right: 15px;

      &.icons {
        margin-right: 10px;
        margin-top: 40px;
        margin-bottom: 40px;
        color: #fff;
        font-size: 16px;

        li {
          margin-right: 25px;
        }
      }

      li {
        display: inline-block;

        a {
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  footer {
    .content-footer-wrapper {
      ul {
        margin-right: 15px;
      }
    }
    .content-footer-wrapper-left {
      text-align: left;
    }

    .content-footer-wrapper-middle {
      .icons {

        li {
          display: inline-block;
        }
      }
    }

    .content-footer-wrapper-right {
      text-align: right;
    }
  }
}