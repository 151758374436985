.pagination {
    li {
        span,
        a {
            border: none;
            color: #000;
            
            &:hover {
                background: none;
            }
        }

        &.active {
            span,
            a {
                background: none;
                color: #C42D46;
                font-weight: bold;
                
                &:hover {
                    background: none;
                    color: #C42D46;
                }
            }
        }

    }
}