.content-wrapper {

    h1, h2, p {
        text-align: center;
        padding: 0 15px;
    }

    h1,h2{
        font-size: 22px;
    }
    p{
        font-size: 14px;
    }
    background-color: #F8F8F8;
    padding-bottom: 30px;

    .foto-wrapper {
        img {
            padding-right: 40px;
        }
    }

    .btn-gold{
        position: absolute;
        left: 50%;
        margin-top: 20px;
        transform: translate(-50%, 0);
    }
}

.video-wrapper{
    position: absolute;
        left: 0;
        top: 90px;
        width: 100%;
        height: 100%;
        max-height: 700px;
        // padding-top: $header-carousel-padding-xs;
        overflow: hidden;

        iframe {
            width: 100vw;
            height: 56.25vw;
            min-height: 100vh;
            min-width: 177.77vh;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

}

.wrapper{
    padding-bottom: 70px;
}

.blocks-wrapper {
    .block-wrapper {

        .h2{
            text-align: center;
            padding: 0 100px;
        
        }
        margin-bottom: 20px;

        .image-wrapper {
            position: relative;
            
            img {
                z-index: 1;
            }

            .overlay-button-wrapper {
                z-index: 3;
                position: absolute;
                bottom: 60px;
                left: 50%;
                transform: translateX(-50%);
            }

            .overlay-color-wrapper {
                z-index: 2;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;

                background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
                background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 100%);
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
            }
        }
    }
}

.banner-wrapper {
    background: url('../img/bg-banner.jpg') no-repeat center center;
    background-size: cover;

    h2 {
        text-align: center;
        color: #FFF;
    }

    .icon-wrapper {
        text-align: center;
        padding-bottom: 20px;

        span {
            color: #F5B700;
            font-size: 40px;
        }

        p {
            text-transform: uppercase;
            margin-top: 30px;
            color: #FFF;
            width: 90%;
            margin-left: 10px;
        }
    }
}

.actueel-wrapper {
    .text-wrapper {
        margin-top: 10px;

        h4 {
            font-size: 16px;
        }

        p {
            font-size: 12px;
            color: #999999;
        }
    }
}
#socialwrapper{
    padding: 40px 0;
    background: #f8f8f8;
}

#references {
    padding: 50px 0;
    background: #f8f8f8;

    h2 {
        text-align: center;
        font-size: 40px;
    }

    .reference {
        position: relative;
        padding: 20px 20px;
        border-radius: 10px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-bottom: 30px;

        p {
            font-style: italic;
            font-size: 16px;
            margin: 0;
        }

        &:before {
            content: '“';
            position: absolute;
            top: -10px;
            left: 0;
            font-size: 130px;
            color: #ececec;
            z-index: -1;
            font-family: 'Passion One', sans-serif;
        }
    }
    .person {
        position: relative;
        padding: 20px;

        .icon {
            display: inline-block;
            width: 100px;

            img {
                width: 100%;
            }
        }
        .name {
            position: absolute;
            left: 140px;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;

            span {
                font-weight: bold;
                font-size: 14px;
            }
        }
    }
}
@media (min-width: $screen-sm-min) {
    .content-wrapper {
        .container-fluid {
            padding-bottom: 50px;
        }

        .foto-wrapper {
            position: unset;

            img {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 300px;
            }
        }
    }

    .blocks-wrapper {
        .image-wrapper {
            .overlay-button-wrapper {
                a {
                    white-space: normal;
                }
            }
        }
    }

    .banner-wrapper {
        background-position-x: 0;
    }

    .actueel-wrapper {
        .text-wrapper {
            h4 {
                font-size: 17px;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .content-wrapper {
        .container-fluid {
            padding-bottom: 60px;

            h1, h2, p {
                text-align: center;
                padding: 0 100px;
            
            }
        }

        .foto-wrapper {
            img {
                width: 400px;
            }
        }
    }

    .actueel-wrapper {
        .text-wrapper {
            margin-top: 15px;

            h4 {
                font-size: 18px;
            }
        }
    }
}

@media (min-width: 1181px) {
    .blocks-wrapper {
        .image-wrapper {
            .overlay-button-wrapper {
                a {
                    white-space: nowrap;
                }
            }
        }
    }

    .content-wrapper{
        h1{
            font-size: 40px;
        }
    }

    .actueel-wrapper {
        .text-wrapper {
            margin-top: 20px;

            h4 {
                font-size: 19px;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .content-wrapper {
        .container-fluid {
            padding-bottom: 70px;
        }

        p {
            font-size: 16px;
            line-height: 30px;
        }

        .foto-wrapper {
            img {
                width: 450px;
            }
        }
    }

    .actueel-wrapper {
        .text-wrapper {
            margin-top: 25px;

            h4 {
                font-size: 20px;
            }
        }
    }
}