.btn-gold{
    margin: 0px 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}

.image-wrapper {
    margin: 100px 0 50px;
    img {
        width: 100%;
        max-width: 570px;
        box-shadow: 20px 25px 30px 0px rgba(0, 0, 0, 0.1);
    }
}

.content{
    padding-right: 100px;
}


.side {
    background-color: #F8F8F8;
    height: 100%;

    .block-wrapper {

        &.top {
            margin-top: 180px;
        }
        
        margin-bottom: 50px;

        .image-wrapper {
            position: relative;
            box-shadow: 20px 25px 30px 0px rgba(0, 0, 0, 0.1);

            img {
                width: 107%;
            }

            .overlay-button-wrapper {
                position: absolute;
                bottom: 20%;
                left:50%;
                transform: translateX(-50%);
            }
        }
    }

   
}

ul {
    li {
        margin-bottom: 5px;
    }
}

.nav-blocks {
    background-color: #F8F8F8;

    .block-wrapper {
        .image-wrapper {
            margin-bottom: 10px;

            img {
                width: 100%;
            }

            .overlay-button-wrapper {
                position: absolute;
                bottom: 20%;
                left:50%;
                transform: translateX(-50%);

                .btn-buddies {
                    padding: 15px 10px;
                    font-size: 13px;
                }
            }
        }
    }
}

.gallery {
    margin-bottom: 50px;

    .popup-gallery {
        .image-link {
            margin-bottom: 30px;
        a {
            margin-bottom: 15px;
            }
        }
    }
}

iframe {
    margin-top: 115px;
    margin-bottom: 70px;
}

.arrangementen {
    h3 {
        background: #0aa2de;
        text-align: center;
        padding: 20px 15px;
        font-weight: 700;
        margin-bottom: 0;
        color: #000;
    }

    div.grijs-licht {
        padding: 20px 20px;
        background: #f5f5f7;
    }

    div.grijs-donker {
        padding: 20px 20px;
        background: #e3e3e3;
    }

    ul {
        padding-left: 17px;

        li {
            margin: 0;
        }
    }
}

.contact {

    background: #1c1a2c; 
    padding: 10px;
    margin: 80px 0;
    color: #fff;

    h2 {
        font-size: 30px;
        font-weight: 700;
        }

   

    ul {
        padding-left: 17px;

        li {
            margin: 0;
        }
    }
}

@media (min-width: $screen-sm-min) {
    .nav-blocks {
        .block-wrapper {
            .image-wrapper {
                .overlay-button-wrapper {
                    .btn-buddies {
                        padding: 15px 5px;
                        font-size: 11px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .nav-blocks {
        .block-wrapper {
            .image-wrapper {
                .overlay-button-wrapper {
                    .btn-buddies {
                        padding: 15px 10px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .side {
        .block-wrapper {
            .image-wrapper {
                .overlay-button-wrapper {
                    a {
                      font-size: 12px;
                      padding: 15px 5px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1250px) {
    .side {
        .block-wrapper {
            .image-wrapper {
                .overlay-button-wrapper {
                    a {
                      font-size: 13px;
                      padding: 15px 10px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1300px) {
    .side {
        .block-wrapper {
            .image-wrapper {
                .overlay-button-wrapper {
                    a {
                      font-size: 14px;
                    }
                }
            }
        }
    }
}