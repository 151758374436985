* {
    font-family: 'Montserrat', sans-serif;
}

img {
    max-width: 100%;

    &.logo {
        width: unset;
    }
}

.container-fluid {
    margin: 0 auto;
    min-width: 320px;
    max-width: 1440px;
    position: relative;
}

.container {
    margin: 0 auto;
    max-width: 1440px;
}

.no-padding {
    padding: 0;
}

.wrapper {
    padding-top: 30px;
    padding-bottom: 30px;

    &.gallery {
        padding-bottom: 10px;
    }
}

h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
}

h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
}

h3 {
    margin-bottom: 20px;

    &.red {
        color: #000;
        line-height: 25px;
    }
}

a {
    color: #000;
    text-decoration: none;

    &:hover {
        color: #0aa2de;
        text-decoration: none;
    }
}

p, li {
    font-size: 15px;
}

@media (min-width: $screen-sm-min) {
    .wrapper {
        padding-top: 50px;
        padding-bottom: 50px;

        &.gallery {
            padding-bottom: 20px;
        }
    }

    h1,h2 {
        font-size: 31px;
        margin-bottom: 30px;
    }

    h3 {
        margin-bottom: 25px;
    }
}

@media (min-width: $screen-md-min) {
    .wrapper {
        padding-top: 60px;
        padding-bottom: 60px;

        &.gallery {
            padding-bottom: 30px;
        }
    }

    .mobile-rows {
        .row {
            &:before {
                display: none;
            }

            &:after {
                display: none;
            }
        }
    }

    h1,h2 {
        font-size: 33px;
        margin-bottom: 40px;
    }

    p, li {
        font-size: 17px;
    }

    h3 {
        margin-bottom: 30px;
    }
}

@media (min-width: $screen-lg-min) {
    .wrapper {
        padding-top: 40px;
        padding-bottom: 40px;
 
        &.gallery {
            padding-bottom: 40px;
        }
    }

    body{
        &.vervolg {
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+80,000000+80 */
            background: rgb(255,255,255); /* Old browsers */
            background: -moz-linear-gradient(left, rgba(255,255,255,1) 80%, rgba(248,248,248,1) 80%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(255,255,255,1) 80%,rgba(248,248,248,1) 80%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(255,255,255,1) 80%,rgba(248,248,248,1) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=1 ); /* IE6-9 */
        }
    }

    h1 {
        font-size: 32px;
        margin-bottom: 24px;
        font-weight: 700;
    }

    h2 {
        font-size: 32px;
        margin-bottom: 30px;
        font-weight: 700;
    }

    p, li {
        font-size: 14px;
        line-height: 28px;
    }

    h3 {
        margin-bottom: 20px;
        font-weight: bold;
        color: #0aa2de;
    }

    h4 {
        margin-bottom: 15px;
        font-weight: bold;
    }
}