header {
  min-height: 250px;
  height: 25vh;
  max-height: 700px;
  position: relative;
  background: url(/img/header.jpg) top center no-repeat;
  background-size: cover;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /*background: linear-gradient(to bottom, rgba(0, 0, 0, .7) 0%, transparent 100%);*/
  }

  &.vervolg {
    height: 40vh;
    max-height: 350px;

    .payoff {
      display: none;
    }
  }

  &.objectheader {
    height: 70px;
    min-height: 70px;
    background: #000 !important;
  }

  .nav_wrapper {
    transition: all 0.2s;
    background: #fff;

    .container-fluid {
      position: relative;

      .logo {
        position: relative;
        z-index: 11;
        display: block;
        margin: 15px 0;
        width: 160px;
      }

      .contact-info {
        position: absolute;
        top: 20px;
        right: 45px;
        font-size: 20px;

        a {
          text-decoration: none;
          color: #000;

          .icon {
            font-size: 28px;
            margin-right: 15px;
          }

          .text {
            display: none;
          }

          &:hover {
            color: #0aa2de;
          }
        }
      }

      .button_wrapper {
        position: absolute;
        top: 17px;
        right: 15px;
        z-index: 11;

        ul {
          display: block;
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            display: inline-block;

            &.round,
            &.nav-button {
              a {
                display: block;
                width: 36px;
                height: 36px;
                border: 1px solid #CCC;
                line-height: 36px;
                border-radius: 0;
                color: #000;
                font-weight: 700;
                text-decoration: none;
                text-align: center;
                transition: all 0.2s;

                span {
                  line-height: 34px;
                }
              }
            }

            &.nav-button {
              margin-left: 15px;

              a {
                border: 0;
                color: #000;
                font-weight: 700;
                font-size: 20px;

                span {
                  vertical-align: 2px;

                  &.icon-menu {
                    color: #000;
                    font-size: 44px;
                    vertical-align: -8px;
                  }
                }
              }
            }

            a.btn {
              margin-top: -5px;

              &.btn-offerte {
                margin-top: -26px;
                margin-left: 20px;
              }
            }
          }
        }
      }

      nav {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 100%;
        display: none;
        z-index: 10;
        overflow: hidden;
        background: #fff;
        padding-top: 70px;

        ul {
          list-style: none;
          padding: 0px;
          margin: 0px;
          // border-bottom: 1px solid #999;

          li {
            // border-top: 1px solid #999;

            a {
              display: block;
              //color: #000;
              color: #000;
              font-weight: 700;
              text-decoration: none;
              text-transform: uppercase;
              font-size: 14px;

              line-height: 26px;
              padding: 5px 15px;
              user-select: none;

              &:hover,
              &.active {
                color: #0aa2de;
              }

              span {
                display: block;
                font-size: 12px;
                float: right;
                line-height: 30px !important;
                transform: rotate(180deg);
                transition: all 0.2s;

                &.toggle {
                  transform: rotate(0deg);
                }
              }

            }

            > ul {
              display: none;

              > li {
                a {
                  white-space: nowrap;
                  padding-left: 35px;
                }
              }
            }
          }
        }
      }
    }

    &.fixed {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 10;
      background: rgba(0, 0, 0, 0.7);
    }
  }

  .payoff {
    position: absolute;
    top: calc(50% + 35px);
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;

    a {
      margin-top: 15px;
    }

    h2 {
      letter-spacing: 22px;
      font-weight: 600;
    }

    h2, h3, h4 {
      color: #FFF;
      text-transform: uppercase;
      margin-bottom: 0;

    }

    h3 {
      text-transform: none;
      margin-bottom: 10px;
      font-weight: 400;
    }

    h4 {
      margin-bottom: 0;
      font-size: 18px;

      a {
        color: #FFF;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm-min) {
  header {
    height: 35vh;

    .nav_wrapper {
      .container-fluid {
        .logo {
          //width: 60px;
        }

        nav {
          ul {
            li {
              a {
                color: #000;
              }
            }
          }
        }
      }
    }

    .payoff {
      h2 {
        font-size: 40px;
      }

      h3, h4 {
        font-size: 24px;
      }
    }
  }
}

// *****************************************************************
// MD
// *****************************************************************

@media (min-width: $screen-md-min) {

  header {
    height: 50vh;

    .nav_wrapper {
      .container-fluid {

        .logo {
          width: 120px;
        }

        .contact-info {
          top: 20px;
          right: 15px;

          a {
            .icon {
              font-size: 24px;
            }
          }
        }

        nav {
          display: block !important;
          padding: 0;
          top: 18px;
          right: 52%;
          transform: translate(50%, 0);
          width: auto;
          overflow: visible;
          background: none;
          transition: all 0.2s;

          > ul {
            border-bottom: none;
            white-space: nowrap;
            margin-left: 40px;

            > li {
              border-top: none;
              position: relative;
              display: inline-block;
              margin: 0 5px;

              a {
                padding: 0;
                font-size: 14px;

                span {
                  display: none;
                }

                &:after {
                  position: absolute;
                  left: 50%;
                  bottom: 0;
                  display: block;
                  content: '';
                  width: 0;
                  height: 1px;
                  background: #0aa2de;
                  transition: all 0.2s;
                }
              }

              &:hover > a,
              > a.active {
                position: relative;
                background: none;

                &:after {
                  left: 10px;
                  width: calc(100% - 20px);
                }
              }

              > ul {
                position: absolute;
                top: 100%;
                left: 0px;
                text-align: left;
                display: block;
                z-index: 10;
                // width: 92.5%;
                overflow: hidden;
                transition: all 0.3s;
                transform: translate(0, 15px);
                opacity: 0;
                visibility: hidden;
                border: 0;
                background-color: #fff;

                > li {
                  display: block;
                  border-top: none;

                  a {
                    padding: 10px 10px 0px 10px;
                    text-transform: none;

                    // &.active {
                    //   background: rgba(0, 0, 0, 0.2);
                    // }
                  }
                }
              }

              &:hover {
                ul {
                  transform: none;
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }

        .button_wrapper {
          transition: all 0.2s;
        }
      }
    }

    .payoff {
      h2 {
        font-size: 50px;
      }

      h3 {
        font-size: 30px;
      }

      h4 {
        font-size: 24px;
      }

      button {
        background: none;
        padding: 10px;
        border: 1px solid #fff;
        color: #fff;
      }
    }
  }
}

// *****************************************************************
// LG
// *****************************************************************

@media (min-width: $screen-lg-min) {
  header {
    height: 150vh;
    &.objectheader {
      height: 150px;
      min-height: 150px;
      background: #000 !important;
    }

    .nav_wrapper {
      .container-fluid {
        .logo {
          margin: 20px 0;
          transition: all 0.2s;
          width: 230px;

          &:focus {
            outline: 0;
          }
        }

        .contact-info {
          top: 30px;
          right: 140px;

          a {
            .icon {
              font-size: 24px;
            }
          }
        }

        nav {
          top: 30px;

          > ul {
            margin-left: 0px;

            > li {
              a {
                font-size: 15px;
                padding: 0px 5px;
              }

              &:hover > a,
              > a.active {
                &:after {
                  left: 10px;
                  width: calc(100% - 20px);
                }
              }
            }
          }
        }

        .button_wrapper {
          top: 36px;
          margin-right: 100px;
        }
      }

      &.fixed {
        .container-fluid {
          position: relative;

          .logo {
            height: 50px;
            margin: 10px 0;
          }

          nav {
            top: 22px;
          }

          .button_wrapper {
            top: 16px;
            
          }
        }
      }
    }

    .payoff {
      a {
        margin-top: 45px;
      }

      h2 {
        font-size: 60px;
      }
    }
  }
}