@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?6ax5he');
    src:  url('fonts/icomoon.eot?6ax5he#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?6ax5he') format('truetype'),
      url('fonts/icomoon.woff?6ax5he') format('woff'),
      url('fonts/icomoon.svg?6ax5he#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-anbi-logo:before {
    content: "\e902";
  }
  .icon-activiteiten:before {
    content: "\e900";
  }
  .icon-algemene-informatie:before {
    content: "\e901";
  }
  .icon-einde-nadert:before {
    content: "\e916";
  }
  .icon-emotionele-bijstand:before {
    content: "\e917";
  }
  .icon-facebook:before {
    content: "\e918";
  }
  .icon-fiscaal-juridisch-advies:before {
    content: "\e919";
  }
  .icon-menu:before {
    content: "\e91a";
  }
  .icon-netwerk:before {
    content: "\e91b";
  }
  .icon-twitter:before {
    content: "\e91c";
  }
  